/*Shared styles*/

.one-half-image
{
  background-color:var(--highlight);
  display:block;
  margin-left:auto;
  margin-right:auto;
  max-width:70vmin;
  border-radius: 0.5rem;
}

.big
{
  display:block;
  margin-left: auto;
  margin-right: auto;
  color:var(--highlight);
  width:25rem;
  height:25rem;
}

.icon-list
{
  display:inline-block;
  color:var(--highlight);
  width:15rem;
  height:15rem;
}

.verticalRoom
{
  margin-top:8rem;
}

.prettyB P B
{
  color:var(--impact);
}

.unselected
{
  transition-property: color;
  transition-duration:200ms;
  color:var(--font)
}

.selected
{
  transition-property: color;
  transition-duration:200ms;
  color:var(--highlight)
}

path.progress
{
  fill:none;
  fill-rule:evenodd;
  stroke:var(--hints);
  stroke-width:2.5;
  stroke-linecap:butt;
  stroke-linejoin:miter;
  stroke-miterlimit:4;
  stroke-dasharray:none;
  stroke-opacity:1;
  transition-property: stroke;
  transition-duration:200ms;
}

.centralImage
{
  width:80vmin;
  display:block;
  margin-left: auto;
  margin-right: auto;
}

circle.progress
{
  fill-opacity:1;
  stroke-width:2;
  stroke-opacity:1;
  stroke-miterlimit:4;
  stroke-dasharray:none;
  fill:var(--hints);
  stroke:var(--hints);
  transition-property: stroke, fill;
  transition-duration:200ms;
}

circle.selected
{
  fill:var(--impact);
  stroke:var(--impact);
}

path.selected
{
  stroke:var(--impact);
}

.slideSixDetails
{
  width:60%;
  overflow:visible;
  margin-left:auto;
  margin-right:auto;
}
