:root
{
  --hints: #E2E1E1;
  --font: #202020;
  --impact: #EB51B7;
  --background: #D1F3EC;
  --highlight: #4F9ED0;
}

.slideshow
{
  padding-top:1vh;
  padding-bottom:1vh;
  padding-left: 1rem;
  padding-right:1rem;
  margin:0;
  height:98vh;
}

.slideshow header
{
  display:flex;
  justify-content: space-between;
  height:4vh;
  min-height:16px;
}

.slideshow nav
{
  display:flex;
  justify-content:center;
  height:4vh;
  min-height:24px;
  margin:0;
  padding:0;
  margin-top:2vh;
}

.slide
{
  border-style: solid;
  border-width: thin;
  border-color:var(--hints);
  height:86vh;
  padding:1vh;
}

.slideHeader
{
  font-size: 4rem;
  padding-right:1rem;
}

.slide
{
    font-size: 4rem;
}

.slideBody
{
  font-size: 2rem;
}

.iconText
{
  display: flex;
  align-items: center;
}

.navButton
{
  border:0;
  padding:0;
  margin:0;
  margin-left:2rem;
  margin-right:2rem;
}
